.text-large {
    @include semi-bold;

    @include large-screen {
        font-size: 3.2rem;
        line-height: 4.2rem;
    }

    @include small-screen {
        font-size: 2.6rem;
        line-height: 2.9rem;
    }

    
}

.text-medium {
    @include regular;

    @include large-screen {
        font-size: 3rem;
        line-height: 4rem;
    }

    @include small-screen {
        font-size: 2.4rem;
        line-height: 3.4rem;
    }
}

.text-small {
    @include regular;

    @include large-screen {
        font-size: 2.8rem;
        line-height: 3.5rem;
    }

    @include small-screen {
        font-size: 2.2rem;
        line-height: 3rem;
    }
}

.text-span {
    @include regular;

    @include large-screen {
        font-size: 2.2rem;
        line-height: 2.8rem;
    }

    @include small-screen {
        font-size: 2rem;
        line-height: 2.25rem;
    }
}