
/* Sayfaların sağ üst köşesinde gösteriliyor */

#logo-gain {
    z-index: 10;
    position: absolute;
    display: block;
    background-image: url('/assets/img/logo.svg');
    background-size: cover;
    background-repeat: no-repeat;
   // width: 9.1rem;
    width: 11.6rem;
    height: 4.9rem;

    @include large-screen {
        top: 4rem;
        right: 13rem;
        width: 9.1rem;
        height: 4.9rem;
    }

    @include small-screen {
        top: 3rem;
        right: 8rem;
        width: 9.1rem;
        height: 4.9rem;
    }
}