.primary-checkbox {
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 75rem;
	//margin: 0 auto 3.5rem auto;

	p {
		@include regular();
		font-size: 1.4rem;
		line-height: 2rem;
		color: $color-dark-grey;
		letter-spacing: 0;
		margin-left: 1.5rem;

		a {
			color: $color-primary;
			text-decoration: underline;
		}
	}

	[type="checkbox"]:checked,
	[type="checkbox"]:not(:checked) {
		position: absolute;
		left: -9999px;
	}

	[type="checkbox"]:checked + label,
	[type="checkbox"]:not(:checked) + label {
		position: relative;
		padding-left: 2.6rem;
		cursor: pointer;
		display: inline-block;
		color: #666;
		height: 2.4rem;
	}

	[type="checkbox"]:checked + label:before,
	[type="checkbox"]:not(:checked) + label:before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 2.2rem;
		height: 2.2rem;
		border: 0.2rem solid $color-primary;
		background: transparent;
	}

	// [type="checkbox"]:checked + label:before {
	// 	border: 0.1rem solid $color-primary;
	// }

	// [type="checkbox"]:not(:checked) + label:before {
	// 	border: 0.1rem solid $border-color;
	// }

	[type="checkbox"]:checked + label:after,
	[type="checkbox"]:not(:checked) + label:after {
		content: "";
		width: 1rem;
		height: 1rem;
		background: $color-primary;
		position: absolute;
		top: 0.6rem;
		left: 0.6rem;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}

	[type="checkbox"]:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		-moz-transform: scale(0);
		-o-transform: scale(0);
		-ms-transform: scale(0);
		transform: scale(0);
	}

	[type="checkbox"]:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-o-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	[type="checkbox"]:disabled:checked + label:before,
	[type="checkbox"]:disabled:not(:checked) + label:before {
		//border-color: transparent;
		opacity: 0.5;
		//background-color: $color-primary;
		cursor: not-allowed;
	}

	[type="checkbox"]:disabled:checked + label:after {
		content: "";
		width: 1.6rem;
		height: 1.6rem;
		background: $color-primary;
		opacity: 0.5;
		position: absolute;
		top: 0.6rem;
		left: 0.6rem;
		-webkit-transition: all 0.2s ease;
		-moz-transition: all 0.2s ease;
		-o-transition: all 0.2s ease;
		transition: all 0.2s ease;
		cursor: not-allowed;
	}
}
