h1,
h2,
h3 {
    @include semi-bold;
}

h4,
h5 {
    @include light;
}

h1 {
    @include large-screen {
        font-size: 7rem;
        line-height: 8rem;
    }

    @include small-screen {
        font-size: 5.5rem;
        line-height: 6.1rem;
    }
}

h2 {
    @include large-screen {
        font-size: 6rem;
        line-height: 6.6rem;
    }

    @include small-screen {
        font-size: 4.5rem;
        line-height: 5rem;
    }
}

h3 {
    @include large-screen {
        font-size: 4.5rem;
        line-height: 5.1rem;
    }

    @include small-screen {
        font-size: 3.4rem;
        line-height: 3.8rem;
    }
}

h4 {
    @include large-screen {
        font-size: 4rem;
        line-height: 4.8rem;
    }

    @include small-screen {
        font-size: 3rem;
        line-height: 3.3rem;
    }
}

h5 {
    @include large-screen {
        font-size: 4rem;
        line-height: 4.8rem;
    }

    @include small-screen {
        font-size: 3rem;
        line-height: 3.3rem;
    }
}