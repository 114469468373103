.main-wrapper{

    &.settings{
        .back-button{
            left: 6rem;
            z-index: 9;
        }

        #logo-gain{
            top: 4rem;
            right: 6rem; 
        }
    }

    .setting-wrapper {
        z-index: 4;
        position: absolute; 
        display: inline-block;   

        @include large-screen {
            left: 67rem; 
            width: calc(100% - 67rem);
            top: 24rem;
            padding-right: 9.5rem;
        }

        @include small-screen {
            left: 35.5rem; 
            width: calc(100% - 35.5rem);
            top: 15rem;
            padding-right: 6rem;
        } 
    }
    
}

.user-profile-svg{
    display: inline-flex;
    position: relative;
    
    svg{

        @include large-screen { 
            + svg{
                display: none;
            }
        } 

        @include small-screen {
             display: none;
             + svg{
                display: block;
            }
        }  

    }

    span{
        @include center(both);
        @extend h1; 
        color: $color-lighter-grey;
        @include semi-bold;  
    }

}


.subscription-icon{

    &.active{

        svg{
            #path-1{
                fill: #00C17C;
            } 
            #path-2{
                fill: #00C17C;
            } 
            #path-3{ 
                fill: #00C17C;
            } 
            #path-4{ 
                fill: #00C17C;
            } 
        }

    }
   
}
 
.user-profile-card{
    display: flex;
    align-items: center;

    @include large-screen {
        margin-top: 8rem;
        margin-bottom: 10rem;
    }

    @include small-screen {
        margin-top: 7rem;
        margin-bottom: 8.5rem; 
    }

    + .btn{
        display: inline-flex
        
        ;
    }

    .user-profile-svg{

        @include large-screen {
            margin-right: 7rem; 
        } 

        @include small-screen {
            margin-right: 11.3rem;
        }

    }

    h4{
        display: block;
        margin-bottom: 3rem;
        @include  semi-bold;

        @include small-screen {
          font-size: 3rem;
          line-height: 3.3rem;
        }

    }
    
    .email-title{ 
        @include regular;

        @include large-screen {
            font-size: 3rem;
            line-height: 4rem;
        } 

        @include small-screen {
            font-size: 2.4rem;
            line-height: 3.4rem;
        }
    }
}



.sign-out-wrapper{
   
    @include large-screen {
        margin-top: 5.5rem; 
    } 

    @include small-screen {
        margin-top: 6.6rem; 
    }

    h4{
        @include semi-bold;
    }

    .btn-wrapper{  

        @include large-screen {
            margin-top: 10rem;
        } 

        @include small-screen {
            margin-top: 6.6rem;
        }

        .btn{
           & + .btn{ 

                @include large-screen {
                    margin-left: 3.5rem;
                } 

                @include small-screen {
                    margin-left: 2rem;
                }
            }
        }
    }
  
}


.subscription-wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    margin-top: 7rem; 

    @include large-screen { 
        margin-bottom: 9.5rem;
    } 

    @include small-screen { 
        max-width: 60rem;
        margin-bottom: 4rem;
    }

    .info-area{ 
        text-align: right; 
        margin-left: 12.5rem; 

        &.not-premium{
            align-self: stretch;
            h4{
                margin-top: 3rem;
                margin-bottom: 0;   
            }
        }

        h4{
            display: block;
            margin-bottom: 2.4rem;
            color: #fff;
            @include semi-bold; 
        }

        span{
            @include medium; 
            color: #fff;
            display: block;
      
            & + span{ 

                @include large-screen {
                    margin-top: 2.5rem;
                } 

                @include small-screen { 
                    margin-top: 3rem;
                }
            }

            @include large-screen {
                font-size: 3.2rem;
                line-height: 3.5rem;
                margin-right: 2.5rem;
            } 

            @include small-screen { 
                font-size: 2.6rem;
                line-height: 2.8rem;
            }
        }
    }
}



.subscription-payment-note{ 
    background-color: $color-light-black;
    border-radius: 1.2rem;  
    color: $color-lighter-grey;
    @include regular;

    @include large-screen {
        margin-top: 2.5rem;
        padding: 3rem 6rem 4.5rem 4.5rem;
        font-size: 2.8rem;
        line-height: 3.5rem;
    } 

    @include small-screen { 
        margin-top: 2rem;
        padding: 2rem 4rem 3rem 4rem;
        font-size: 2.2rem;
        line-height: 3rem;
    }
    
    p{ 
        + p {
            margin-top: .5rem;
        }
    }
}