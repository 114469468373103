.textbox {
	@extend .text-medium;
	@include medium;
	width: 100%;
	display: block;
	background-color: $color-lighter-black;
	color: #FFFFFF;
	appearance: none;

	@include large-screen {
		padding-left: 4rem;
		height: 9rem;
		line-height: 9rem;
		border-radius: 9rem;
		border: 0.4rem solid transparent;
	}

	@include small-screen {
		padding-left: 2.5rem;
		height: 6rem;
		line-height: 6rem;
		border-radius: 6rem;
		border: 0.25rem solid transparent;
	}

	&:disabled {
		background: #f9f9f9;
		color: #999999;
		cursor: not-allowed;
	}

    &.selected,
	&:focus,
	&:hover {
		border-color: #FFFFFF;
		outline: none;
	}

	&::placeholder {
		color: $color-light-grey;
	}
}

/* Change Autocomplete styles in Chrome*/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus {
//   border: none !important;
//   -webkit-text-fill-color: #ffffff !important;
//   background-color: $color-black !important;
//   -webkit-box-shadow: 0 0 0 100rem $color-black inset; /* background rengini değişir */
//   transition: background-color 5100s ease-in-out 0s;
// }

// @-webkit-keyframes autofill {
//   to {
//     color: #ffffff !important;
//     background: transparent;
// 	border: none !important;
//   }
// }

// input:-webkit-autofill {
// 	border: none !important;
//   -webkit-animation-name: autofill;
//   -webkit-animation-fill-mode: both;
// }