

.main-wrapper{
    &.title-detail-wrapper{ 
        .page-gradient{
            position: absolute;
            left: 0;right: 0;bottom: 0;top: 0;
            background: rgba(17,17,17,0.9); 
            z-index: 2;

            &:after{
                content: '';
                position: absolute;
                left: 0;right: 0;bottom: 0;top: 0;
                background: linear-gradient(0deg, #111111 0%, rgba(17, 17, 17, 0) 64.06%);
            }
        } 

        .back-button{
            left: 6rem;
 
        }

        #logo-gain{
            top: 4rem;
            right: 6rem;
             
            @include small-screen {  
                display: none;
            }

        }

        .media-summary{
            left: 6rem;
            height: auto;

            @include large-screen {
                max-width: 65rem;
                top: 12rem;
            } 
        
            @include small-screen {  
               max-width: 40rem;
               top: 10rem;
            }
        }
    }   
}

.title-detail-content{
    position: absolute;
    right: 0; 
    bottom: 0; 
    top: 4rem; 
    width: 70%;
    z-index: 3;
    padding-right: 6rem; 
    overflow: hidden;
    
    @include large-screen {
        max-width: 112rem;    
    } 

    @include small-screen {  
        max-width: 74rem;
    }
    
    .title-detail-list{
        will-change: transform;
    }
}
 