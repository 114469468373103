.setting-wrapper{
    &.skeleton{

        .user-profile-svg{ 
            border-radius: 50%;

            @include large-screen { 
                width: 24.3rem;
                height: 24.3rem;
            } 
    
            @include small-screen {
                width: 19.1rem;
                height: 19.1rem;
            }  

        }

        h4{
            border-radius: 1rem;
            width: 50rem;
            height: 4.8rem;
        }

        .email-title{
            width: 32rem;
            height: 3.4rem; 
            display: inline-block;
            border-radius: 1rem;
        }

        .btn-sign-out{
            width: 23rem;
        }

        .info-area{

            span{
                border-radius: 1rem;
                width: 60%;
                margin-left: auto;
                
                @include large-screen { 
                    height: 3.5rem; 
                } 
    
                @include small-screen {  
                    height: 2.8rem;
                }
            }

        }
        
    }
}