
.title-detail-track{
    &:last-child{
        margin-bottom: 0;
    }
    @include large-screen { 
        margin-bottom: 4rem;  
    }  
    @include small-screen { 
        margin-bottom: 3rem;  
    } 
}

.episode-card{
    display: flex;
    align-items: center; 
    &:last-child{
        margin-bottom: 0;
    }
    @include large-screen {
        padding-bottom: 0.4rem;
        margin-bottom: 4rem; 
        min-height: 21.5rem;
    } 

    @include small-screen { 
        margin-bottom: 3rem; 
        min-height: 13.5rem;
    }

    &:hover,
    &:focus,
    &.selected{
        .banner{        
            &:after{
                opacity: 1;
            }
            .line{
                height: 0.8rem;
            }
        }
    }

    em{
        font-style: normal;
    }

    .banner{ 
        position: relative;  
        display: inline-flex;
        border-radius: 1rem; 
        overflow: hidden;

        @include large-screen {
            margin-right: 4.4rem;
            min-width: 37.5rem;
        } 
  
        @include small-screen {
            margin-right: 2rem;
            min-width: 21rem;
        }

        &:before{
            content: '';
            position: absolute;
            left: 0;right: 0;bottom: 0;top: 0;
            background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, rgba(17, 17, 17, 0.5) 100%);
            border-radius: 1rem;
            z-index: 1;
        }

        &:after{
            content: '';
            position: absolute; 
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            z-index: 3; 
            border-radius: 1rem; 
            opacity: 0;
            border: 0.4rem solid #ED1C24;
            
            // @include large-screen {
            //     border: 0.4rem solid #ED1C24;
            // } 

            // @include small-screen {
            //     border: 0.2rem solid #ED1C24;
            // }
        }

        img,.img{
          object-fit: cover;
          border-radius: 1rem; 
            
            @include large-screen {
                width: 37.5rem;
                height: 21rem;
            } 

            @include small-screen {
                width: 21rem;
                height: 12rem;
            }
        }

        span{
            position: absolute;
            display: inline-flex;
            align-items: center;  
            z-index: 2;
            @include regular;   
            color: #FFFFFF; 

            @include large-screen {
                left: 2.1rem;
                bottom: 2.8rem;
                font-size: 2.8rem;
                line-height: 3.5rem;  
            } 

            @include small-screen {
                left: 0.7rem;
                bottom: 1.2rem;
                font-size: 2.2em;
                line-height: 3rem;  
            }
        }

        .play-icon{
            display: inline-flex;
            align-items: center;
            justify-content: center;  
            border-radius: 50%;
            background-color: $color-lighter-black;
            margin-right: .5rem;
            background-image: url("data:image/svg+xml,%3Csvg width='23' height='29' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m2.18 2.17 19.09 12.27L2.17 26.7V2.17Z' stroke='%23fff' stroke-width='2.73' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-position: center center;
            background-repeat: no-repeat;
             
            @include large-screen {
                width: 6rem;
                height: 6rem;
                background-size: 1.8rem;
            } 

            @include small-screen {
                width: 5rem;
                height: 5rem;
                background-size: 1.6rem;
            }

        }

        .line{
            position: absolute;  
            background-color: $color-new-more-dark-grey; 
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            overflow: hidden;
            z-index: 1;
            border-radius:.5rem;

            @include small-screen {
                bottom: 1rem;
                left: 1rem;
                right: 1rem;
                height: 0.5rem;
            }

            @include large-screen {
                bottom: 1.2rem;
                left: 1.5rem;
                right: 1.5rem;
                height: 0.8rem;
            } 

            i{
                background-color: $color-primary;
            }
        }

    }

    .detail{
        flex-grow: 1; 

        span{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include semi-bold;  
            color: #fff; 

            @include large-screen {
                font-size: 3.2rem;
                line-height: 3.5rem;
                margin-bottom: 1.5rem; 
            } 

            @include small-screen {
                font-size: 2.6rem;
                line-height: 2.8rem;
                margin-bottom: 0.5rem; 
            }

        }

        p{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            color: #fff;
            @include regular; 
             
            @include large-screen {
                font-size: 2.8rem;
                line-height: 3.5rem;
                margin-bottom: 1.5rem;
            } 

            @include small-screen {
                font-size: 2rem;
                line-height: 2.5rem;
                margin-bottom: 0.5rem;
            }

        }
        
        i{
            font-style: normal;
            display: block;
            @include regular;   
            color: $color-semi-light-grey; 

            @include large-screen {
                font-size: 2.4rem;
                line-height: 2.6rem; 
            } 

            @include small-screen {
                font-size: 2rem;
                line-height: 2.2rem; 
            }

        }

    }
} 