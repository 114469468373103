﻿
@font-face {
	font-family: "phantom-sans-light";
		src: url('/assets/fonts/phantom-sans/PhantomSans0.8-Light.woff') format('woff'),
 		url('/assets/fonts/phantom-sans/PhantomSans0.8-Light.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
}

@mixin light {
	font-family: "phantom-sans-light";
}



@font-face {
	font-family: "phantom-sans-regular";
	src: url('/assets/fonts/phantom-sans/PhantomSans0.8-Regular.woff') format('woff'),
		url('/assets/fonts/phantom-sans/PhantomSans0.8-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@mixin regular {
	font-family: "phantom-sans-regular";
}



@font-face {
	font-family: "phantom-sans-medium";
	src: url('/assets/fonts/phantom-sans/PhantomSans0.5-Medium.woff') format('woff'),
		url('/assets/fonts/phantom-sans/PhantomSans0.5-Medium.woff2') format('woff2'),
		url('/assets/fonts/phantom-sans/PhantomSans0.5-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@mixin medium {
	font-family: "phantom-sans-medium";
}


@font-face {
	font-family: "phantom-sans-semi-bold";
	src: url('/assets/fonts/phantom-sans/PhantomSans0.5-Semibold.woff') format('woff'),
		url('/assets/fonts/phantom-sans/PhantomSans0.5-Semibold.woff2') format('woff2'),
		url('/assets/fonts/phantom-sans/PhantomSans0.5-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@mixin semi-bold {
	font-family: "phantom-sans-semi-bold";
}


// @font-face {
// 	font-family:  "phantom-sans-bold";
// 	src: url('/assets/fonts/phantom-sans/PhantomSans-Bold.woff') format('woff'),
// 		url('/assets/fonts/phantom-sans/PhantomSans-Bold.woff2') format('woff2'),
// 		url('/assets/fonts/phantom-sans/PhantomSans-Bold.ttf') format('truetype');
// 	font-weight: 700;
// 	font-style: normal;
// }

// @mixin bold {
// 	font-family:  "phantom-sans-bold";
// }
