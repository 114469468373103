.textarea {
	@include semi-bold;
	font-size: 1.6rem;
	line-height: 2rem;
    width: 100%;
	display: block;
	color: #ffffff;
	background-color: $color-lighter-black;
	border: none;
    padding: 1.5rem 1.5rem;
    border-radius: 0;
	appearance: none;
	transition: all 0.2s ease-in;
	min-height: 10rem;

	&:disabled {
		background: #f9f9f9;
		color:#999999;
	}

	&::placeholder {
		@include regular;
		color: #b4b4b4;
	}
}

.textarea-full{
	width: 100%;;
}


// KÖŞELERİ YUVARLAR
.textarea-radius {
	border-radius: 0.4rem;
}