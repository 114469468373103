@use "sass:math";

.main-wrapper {

    &.search {

        @include large-screen {
            width: calc(100% - 29.5rem);
            left: 21rem;
        }

        @include small-screen {
            left: 18rem;
            width: calc(100% - 23.5rem);
        }

        .playlist-wrapper {
            padding-left: 0;

            @include large-screen {
                top: 18rem;
                left: 0;
                width: 100%;
            }

            @include small-screen {
                left: 0;
                top: 13rem;
                width: 100%;
            }

            .grid-type {
                flex-wrap: wrap;
                overflow: hidden;
                padding-left: 0; 

                @include large-screen {
                    margin-left: -1rem;
                    margin-right: -1rem;
                }

                @include small-screen {
                    margin-left: -1rem;
                    margin-right: -1rem;
                }

                .item {
                    margin-bottom: 2rem;
                    margin-left: 1rem;
                    margin-right: 1rem;
                    flex-basis: calc(20% - 2rem); 
                    position: relative;
                    border-radius: 1rem;

                    &:before {
                        display: block;
                        content: "";
                        width: 100%;
                        padding-top: math.div(14,25) * 100%;
                    }

                    &:not(.animate){
                        &:after{
                            content: '';
                            position: absolute;
                            left: .4rem;
                            top: .4rem;
                            width: calc( 100% - .8rem );
                            height: calc( 100% - .8rem ); 
                            border-radius: 1rem;
                            // background: linear-gradient(110deg, #222 8%, #333 18%, #222 33%);
                            // background-size: 200% 100%;
                            // animation: 2.5s loading linear infinite;
                            animation: skeleton-loading .6s linear infinite alternate;
                            background-color: $color-lighter-black;
                        }
                    }
 
                    img {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%; 
                        z-index: 2;
                        object-fit: cover;
                    }

                }
            }
        }

    }
}


.search-wrapper {
    z-index: 4;
    position: absolute;
    left: 0;
    width: 100%;

    @include large-screen {
        top: 5rem;
    }

    @include small-screen {
        top: 4rem;
    }

    .textbox {
        background-image: url("data:image/svg+xml,%3Csvg width='38' height='38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 33a16 16 0 1 0 0-32 16 16 0 0 0 0 32ZM37 37l-8.7-8.7' stroke='%23777' stroke-width='2' stroke-linecap='round' strokeLinejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;

        &::-webkit-search-cancel-button {
            display: none;
            -webkit-appearance: none;
        }

        @include large-screen {
            height: 8rem;
            line-height: 8rem;
            background-position: calc(100% - 5rem) center;
            padding-right: 10rem;
        }

        @include small-screen {
            height: 6rem;
            line-height: 6rem;
            background-position: calc(100% - 2.5rem) center;
            padding-right: 6rem;
            background-size: 2.4rem;
        }

    }

    .form-group {
        padding: 0;
    }
}

.search-playlist {}

.play-list-tranform {
    overflow: hidden;
}