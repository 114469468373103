.flex {
    display: flex;
}

/* justify-content */
.flex-jc-start {
    display: flex;
    justify-content: flex-start;
}

.flex-jc-end {
    display: flex;
    justify-content: flex-end;
}

.flex-jc-center {
    display: flex;
    justify-content: center;
}

.flex-jc-space-between {
    display: flex;
    justify-content: space-between;
}

.flex-jc-space-around {
    display: flex;
    justify-content: space-around;
}

/* align-items */
.flex-ai-start {
    display: flex;
    align-items: flex-start;
}

.flex-ai-end {
    display: flex;
    align-items: flex-end;
}

.flex-ai-center {
    display: flex;
    align-items: center;
}