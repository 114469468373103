.video-js {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
}

.video-js video {
    width: 100%;
    height: 100%;
}

.vjs-modal-dialog,
.vjs-control-bar,
.vjs-big-play-button,
.vjs-loading-spinner {
    display: none;
}


// video::cue için aşağıdakiler kullanılabiliyor sadece.
// color
// opacity
// visibility
// text-decoration
// text-shadow
// background
// outline
// font
// line-height
// white-space

.vjs-paused video::cue,
.vjs-paused .vjs-text-track-display{
    /* video durdurulduğunda altyazı gizleniyor */
    opacity: 0;
}