.label {
	display: block;
	@include regular;
	font-size: 1.6rem;
	line-height: 1.8rem;
	padding-bottom: 1.2rem;
	color: #F9F9F9;
	text-align: left;

	.helper {
		color: $color-dark-grey;
		font-size: 1.4rem;
		padding-top: 0.4rem;
		display: block;
	}
}
