// @keyframes skeleton-loading {
//     from{
//       background-size: 200% 100%;
//     } 
//     to {
//       background-position-x: -200%;
//     }
//   } 
  

   
// Background color opacity değiştiriliyor 
// sekelton.css -> .animate -> animation: skeleton-loading 1s linear infinite alternate;
@keyframes skeleton-loading {
  0%{
    background-color: rgba(33,33,33,1);
  } 
  100% {
    background-color: rgba(33,33,33,.5);
  }
} 
