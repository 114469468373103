div.radio {
    margin-right: 4rem;
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    display: inline-block;

    .form-container &{
        margin-top: 0;
    }

    label {
        font-size: 1.6rem;
        @include regular;
        color: $color-black;
    }


    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 4rem;
        cursor: pointer;
        line-height: 3rem;
        display: inline-block;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 2.8rem;
        height:2.8rem;
        border-radius: 100%;
        background-color: transparent;
    }

    // [type="radio"]:checked + label:before{
    //     border: 0.1rem solid $color-primary;
    // }

    // [type="radio"]:not(:checked) + label:before {
    //     border: 0.1rem solid $border-color;
    // }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 1.6rem;
        height:  1.6rem;
        background: $color-primary;
        position: absolute;
        top: 0.6rem;
        left: 0.6rem;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;

        // .radio-small &{
        //     width: 1.4rem;
        //     height: 1.4re
        //     top: 0.3rem;
        //     left: 0.3rem;
        // }
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

