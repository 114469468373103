@mixin large-screen {
    // Full HD: 1920px ve aşağısı
    @media screen and (min-width: 1920px) {
        @content;
    }
}

@mixin small-screen {
    // HD-Ready: 1280px ile 1919px arası
    @media screen and (max-width:1919px) {
        @content;
    }
}