.qr-login-wrapper {
	.content {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;

		@include large-screen {
			padding: 11.6rem 13.5rem 0 11rem;
		}

		@include small-screen {
			padding: 5.4rem 9.6rem 0 6rem;
		}

		> .btn {
			align-self: center;

			@include large-screen {
				margin-top: 7.5rem;
			}

			@include small-screen {
				margin-top: 3.5rem;
				margin-left: 12rem;
			}
		}
	}

	.primary-title {
		display: block;
		@include regular;
		color: $color-semi-light-grey;
		text-align: left;
		line-height: 1;

		@include large-screen {
			font-size: 3.4rem;
		}

		@include small-screen {
			font-size: 2.8rem;
		}

		&.mt {
			@include large-screen {
				margin-top: 3.5rem;
			}

			@include small-screen {
				margin-top: 2.5rem;
			}
		}

		&.mb {
			@include large-screen {
				margin-bottom: 3.5rem;
			}

			@include small-screen {
				margin-bottom: 2.5rem;
			}
		}
	}

	.secondary-title {
		display: block;
		@include light;
		color: $color-semi-light-grey;
		text-align: left;

		@include large-screen {
			font-size: 3.5rem;
			line-height: 4rem;
		}

		@include small-screen {
			font-size: 2.8rem;
			line-height: 3.2rem;
		}

		&.mb {
			@include large-screen {
				margin-bottom: 3.2rem;
			}

			@include small-screen {
				margin-bottom: 2.4rem;
			}
		}
	}

	h2 {
		&.mb {
			@include large-screen {
				margin-bottom: 6rem;
			}

			@include small-screen {
				margin-bottom: 4.4rem;
			}
		}
	}

	.qr-login-main {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
	}

	.left-huge {
		display: inline-flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	}

	.center-huge {
		flex-grow: 1;
		align-self: stretch;
		padding: 0 1rem;
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.primary-title {
			color: $color-dark-grey;
			user-select: none;

			@include large-screen {
				font-size: 2.8rem;
				margin-top: 3.5rem;
				@include light;
				margin: 4rem 0;
			}

			@include small-screen {
				font-size: 2rem;
				margin-top: 2.7rem;
				@include regular;
				margin: 2rem 0;
			}
		}
	}

	.counter-item {
		display: inline-flex;
		align-items: flex-start;
		justify-content: flex-start;
		width: 100%;

		&.first {
			margin-bottom: 10.8rem;

			.primary-title {
				@include large-screen {
					margin-bottom: 1.6rem;
				}

				@include small-screen {
					margin-bottom: 2.4rem;
				}
			}
		}

		&.second {
		}

		.count {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			border-radius: 50%;
			background-color: rgba($color-lighter-black, 0.7);
			color: $color-semi-light-grey;
			line-height: 1rem;
			@include semi-bold;
			user-select: none;

			@include large-screen {
				min-width: 10.6rem;
				width: 10.6rem;
				height: 10.6rem;
				margin-right: 4rem;
				font-size: 6rem;
			}

			@include small-screen {
				min-width: 9rem;
				width: 9rem;
				height: 9rem;
				margin-right: 1.6rem;
				font-size: 4.5rem;
			}

			&.mt {
				@include large-screen {
					margin-top: 1.8rem;
				}

				@include small-screen {
					margin-top: 1.2rem;
				}
			}
		}
	}

	.letter-huge {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.letter-box {
		@include regular;
		color: $color-black;
		border-radius: 0.6rem;
		background-color: $color-light-title-grey;
		display: inline-block;
		text-align: center;
		user-select: none;

		@include large-screen {
			min-width: 8rem;
			font-size: 7.3rem;
			line-height: 8.1rem;
			padding: 1.2rem 1rem;
			margin-right: 1rem;
			height: 10.5rem;
		}

		@include small-screen {
			min-width: 6.2rem;
			font-size: 5.7rem;
			line-height: 6.3rem;
			padding: 0.95rem 0.7rem;
			margin-right: 0.9rem;
			height: 8.2rem;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.counter-text {
		line-height: 1;
		color: $color-semi-light-grey;
		display: block;

		@include large-screen {
			font-size: 2.8rem;
			margin-top: 3.5rem;
			@include light;
		}

		@include small-screen {
			font-size: 2rem;
			margin-top: 2.7rem;
			@include regular;
		}
	}

	.line-elem {
		width: 0.6rem;
		flex-grow: 1;
		background-color: $color-lighter-black;
		opacity: 0.7;
	}

	.qr-code-box {
		display: block;
		text-align: center;

		@include large-screen {
			width: 64rem;
		}

		@include small-screen {
			width: 45rem;
		}

		.secondary-title {
			text-align: center;

			@include large-screen {
				margin-bottom: 3.6rem;
			}

			@include small-screen {
				margin-bottom: 6rem;
			}
		}

		img {
			object-fit: cover;

			@include large-screen {
				width: 38rem;
				height: 38rem;
			}

			@include small-screen {
				width: 25rem;
				height: 25rem;
			}
		}
	}

	// 404

	&.result-page {
		.content {
			padding-top: 0;
			justify-content: center;
			align-items: center;
		}
	}
}

.qr-login-result-main {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;

	h3 {
		margin-bottom: 2rem;
	}

	h4 {
		color: $color-semi-light-grey;
	}

	.btn-group {
		display: flex;
		align-items: center;
		justify-content: center;

		&.mt {
			margin-top: 9rem;
		}

		&.mt-2x {
			margin-top: 19rem;
		}

		.btn {
			margin-right: 4rem;
			&:last-of-type {
				margin-right: 0;
			}
		}
	}

	.absolute-title {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: center;
		color: $color-lighter-grey;

		@include large-screen {
			bottom: -15rem;
		}

		@include small-screen {
			bottom: -10rem;
		}
	}
}
