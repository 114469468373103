// COLORS
$color-black: #111111;
$color-light-black: #222222;
$color-lighter-black: #333333;
$color-light-grey: #8E8E8E;
$color-semi-light-grey: #AAAAAA;
$color-lighter-grey: #CCCCCC;
$color-dark-grey: #777777;
$color-new-more-dark-grey: #555555;
$color-new-light-grey: #575B67;
$color-light-title-grey: #F9F9F9;
$color-primary: #ED1C24;
$color-success: #00C17C;