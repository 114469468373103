html {
    width: 100%;
    height: 100%;
    font-size: 62.5%;
    scrollbar-width: none;
}

body {
    width: 100%;
    height: 100%;
    @include regular;
    background-color: $color-black;
    color: #ffffff;
    overflow-x: hidden;
    -ms-overflow-style: none;
}

#root {
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: 0;
    // left: 0;
    // margin: 0;
    // padding: 0;
    // overflow: hidden;
    // background: 0 0;
}

// .arcelik #root {
//     @include large-screen {
//         width: 192rem;
//         height: 108rem;
//     } 

//     @include small-screen {
//         width: 128rem;
//         height: 72rem;
//     }
// }

::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -ms-overflow-style: none;
}