
.premium-free{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; 
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg-banner{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        object-fit: cover;
    }

    .gradient-wrapper{
        position: absolute;
        left: 0;
        top: 0; 
        width: 100%;
        height: 100%;
        opacity: .8; 
        display: flex;
        align-items: stretch;
        justify-content: center;
        z-index: 2;
        .bg-item{
            position: absolute;
            left: 0;
            top: 0; 
            width: 100%;
            height: 100%;
            &.light{ 
                background-color: rgba(237,28,36,1);
            }
            &.dark{ 
                background-color: rgba(17,17,17,.8); 
            }
        }
       
    }
   

    .content-wrapper{
        position: relative;
        z-index: 3;
        text-align: center;

        @include large-screen { 

        } 
    
        @include small-screen { 
            
        }

        img{
            @include large-screen {
                margin-bottom: 5.5rem;
                height: 16rem
            } 
            @include small-screen { 
                margin-bottom: 1.5rem;
                height: 12rem;
            }
        }

        h2{
            @include large-screen {
                margin-bottom: 3.8rem;
            } 
        
            @include small-screen { 
                margin-bottom: 5.5rem;
            }
        }

        p{
            color: $color-lighter-grey;
            @include regular;
            display: block;
            margin: 0 auto; 
            @include large-screen {
                font-size: 4rem;
                line-height: 8rem;
                max-width: 97rem;
            } 
            @include small-screen { 
                font-size: 3rem;
                line-height: 5rem;
                max-width: 100rem;
            }

            b{
                @include semi-bold;
                color: #fff;
                @include large-screen {
                    font-size: 6rem; 
                } 
                @include small-screen { 
                    font-size: 4.5rem;
                }
            }
        }

        .btn{ 
            display: inline-block; 
            padding: 0;
            @include semi-bold; 
            @include large-screen {
                margin-top: 8rem;
                width: 42rem;
            } 
            @include small-screen { 
                margin-top: 11rem;
                width: 35rem;
            }
        }
    }

}