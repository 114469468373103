.playlist-wrapper {
    z-index: 4;
    position: absolute;
    display: inline-block;
    overflow: scroll;

    #playlist-transformable {
        will-change: transform;
        // transform: translateZ(0);

        @include large-screen {
            padding-bottom: 26rem;
        }

        @include small-screen {
            padding-bottom: 19rem;
        }
    }

    #playlist-transformable>div {
        margin-bottom: 4rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &.bottom {
        top: calc(100% + 4.5rem);

        .playlist {
            margin-bottom: 0;
        }
    }

    @include large-screen {
        padding-left: 21rem;
        top: calc(100% - 23.4rem);
    }

    @include small-screen {
        padding-left: 18rem;
        top: calc(100% - 14.8rem);
    }
}


.playlist {
    margin-top: 2rem;
    // width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;

    @include large-screen {
        margin-left: -21rem;
        padding-left: 21rem;
        margin-bottom: 9rem;
    }

    @include small-screen {
        margin-left: -18rem;
        padding-left: 18rem;
        margin-bottom: 4rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .item {
        margin-right: 2rem;
        display: inline-block;
        outline: none;
        // background-color: $color-light-black;
        border-radius: 1rem;
        //border: 0.4rem solid #111111;
        border: 0.4rem solid transparent;

        &:hover,
        &:focus {
            img {
                border-color: $color-primary;
            }
        }

        &.selected {
            border-color: $color-primary !important;
        }

        img {
            display: block;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 0.6rem;

            @include large-screen {
                width: 35rem;
                height: 19.6rem;
            }

            @include small-screen {
                width: 24.8rem;
                height: 14rem;
            }
        }
    }
}