.playlist-container {
    &.skeleton {

        .text-large {
            display: inline-block;
            height: 4.2rem;
            width: 22rem;
            border-radius: 1rem;
        }

        .playlist {

            .item {
                border-radius: 1rem;

                @include large-screen {
                    width: 35rem;
                    height: 19.6rem;
                }

                @include small-screen {
                    width: 24.8rem;
                    height: 14rem;
                }
            }
        }
    }
}