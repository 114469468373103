.skeleton {
    pointer-events: none;
    user-select: none;
}

.animate {
    // background: linear-gradient(110deg, #222 8%, #333 18%, #222 33%);
    // background-size: 200% 100%;
    // animation: 2.5s skeleton-loading linear infinite;
    will-change: background-color;
    animation: skeleton-loading .4s linear infinite alternate;
    background-color: $color-lighter-black;
}