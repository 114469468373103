.wrapper {
    width: 100%;
    min-height: 100%;

    .bg,
    .image,
    .shadow {
        width: 100%;
        height: 100%;
    }

    .bg {
        position: fixed;
        background-color: rgba(17, 17, 17, 0.5);
        z-index: 1;

        .image {
            position: absolute;
            z-index: 1;
        }

        .shadow {
            position: absolute;
            background-color: rgba(17, 17, 17, 0.98);
            z-index: 2;
        }
    }

    .content {
        z-index: 5;
        position: relative;
        width: 100%;
        height: 100vh;
    }
}

.main-wrapper {
    width: 100%;
    min-height: 100%;
    position: absolute;
    overflow: hidden;

    &.category {
        .media-summary {
            @include large-screen {
                top: 2rem;
                height: auto;
            }

            @include small-screen {
                top: 2rem;
                height: auto;
            }
        }

    }

    &.playlist-item-border {
        .playlist {
            .item {
                // background-color: $color-light-black;
                // border: 0.4rem solid #111111;

                &.selected {
                    border-color: $color-primary;
                }
            }
        }
    }

    &.scroll-active {

        .playlist .item {
            background-color: transparent;
            border: 0.4rem solid #111111;
        }

        &:not(.settings)+#sidebar {
            background-color: rgba(17, 17, 17, .8);
        }

        #home-hero {
            opacity: 0;
            z-index: 1;
        }


        #logo-gain {
            @include small-screen {
                display: none;
            }
        }

        .back-button {
            display: none;
        }

        .media-summary {
            top: 4rem;

            @include large-screen {
                max-width: 90rem;
            }

            .btn-container {
                display: none;
            }

            .description {
                @include small-screen {
                    -webkit-line-clamp: 2;
                }
            }

            .title-buttons {
                display: none;
            }

        }

        .img-container {
            left: auto;
            right: 0;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                background: linear-gradient(90deg, #111111 0%, rgba(17, 17, 17, 0) 92.26%);

                @include large-screen {
                    width: 40rem;
                }

                @include small-screen {

                    width: 21.9rem;
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: linear-gradient(0, #111111 0%, rgba(17, 17, 17, 0) 92.26%);

                @include large-screen {
                    height: 31.6rem;
                }

                @include small-screen {
                    height: 17.3rem;
                }
            }

            @include large-screen {
                height: 63rem;
                width: 111.5rem;
            }

            @include small-screen {
                height: 34.4rem;
                width: 63rem;
            }
        }

        .playlist-wrapper {
            // width: 100%;

            @include large-screen {
                top: calc(100% - 54rem);

                height: calc(100% - 54rem);
            }

            @include small-screen {
                top: calc(100% - 36rem);

                height: calc(100% - 36rem);
            }
        }
    }
}