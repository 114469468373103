#gradient-element{
	position: fixed;
    left: 0;
    bottom: 0;
    top: 0;
    width: 70%;
    background: linear-gradient(90deg, rgba(17, 17, 17, 0.9) 0%, rgba(17, 17, 17, 0) 100%);
    z-index: 1;
	pointer-events: none;
}

#sidebar { 
	z-index: 5;
	position: fixed;  
    height: 100%;

    @include large-screen {
        width: 21rem;
    }

    @include small-screen {
        width: 16rem;
    }

    &.opened + #gradient-element{
        background: linear-gradient(90deg, #111111 30rem, rgba(17, 17, 17, 0) 100%);
        //background: linear-gradient(90deg, #111 calc(30rem - 1px), transparent 100%);
        width: 100%;
        z-index: 5;
    }

    &.settings-sidebar{
        z-index: 4;
		background: unset; 

        @include large-screen {
            width: 40rem;
        }

        @include small-screen {
            width: 30rem;
        } 

		ul{
            position: absolute;
			justify-content: flex-start;
            height: auto;
            
            @include large-screen {
                top: 21.5rem;
            } 

            @include small-screen {
                top: 13rem;
            }
			
			li{
				a{
					span{
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	&.opened {
		z-index: 6;
		
		ul {
			background-color: $color-black;
		}

		span {
			pointer-events: auto;
			visibility: visible;
			opacity: 1;
		}
	}

	ul {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
        width: 100%;
		height: 100%;
		padding-left: 6rem;
        position: relative;
         
		li {
			width: 100%;
			display: block;
			
            @include large-screen {
                margin: 1.2rem 0;
            }

            @include small-screen {
                margin: 0.9rem 0;
            }

			&.title{
				margin-top: 0;
				@include large-screen {
					margin-bottom: 8.6em;
				}

				@include small-screen {
					margin-bottom: 5.5rem; 
				}
			}
			a {
				display: flex;
				align-items: center;
				outline: none;

				svg {
					@include large-screen {
						min-width: 7rem;
						height: 7rem;
					}

					@include small-screen {
						min-width: 5rem;
						height: 5rem;
                    }
                    
                    .border-line{
                        opacity: 0;
                    }

					rect {
						fill: transparent;
					}

					path[stroke] {
						stroke: $color-semi-light-grey;
					}

					path[fill] {
						fill: #AAAAAA;
					}
				}

				span {
					pointer-events: none;
					visibility: hidden;
					opacity: 0;
					margin-left: 1.5rem;
					color: $color-lighter-grey;
					@include regular;

					@include large-screen {
						font-size: 2.8rem;
						line-height: 3.5rem;
					}

					@include small-screen {
						font-size: 2.2rem;
						line-height: 2.4rem;
					}
				}

				// &.active {
				// 	svg {
				// 		rect {
				// 			fill: transparent;
                //             outline-color: $color-semi-light-grey;
                //             outline-style: solid;
                //             outline-width: 0.2rem;
                //             outline-offset: -0.2rem;
                //             border-radius: 50%;
				// 		}

				// 		path[stroke] {
				// 			stroke: $color-semi-light-grey;
				// 		}

				// 		path[fill] {
				// 			fill: $color-semi-light-grey;
				// 		}
				// 	}

				// 	span {
				// 		color: #ffffff;
				// 		@include medium;
				// 	}
				// }

                &.active{
                    svg{ 
                        .border-line{
                            opacity: 1;
                            width: calc( 100% - 0.4rem );
                            height: calc( 100% - 0.4rem );
                        }
                    }
                } 

                &.selected,
                &:focus,
				&:hover{
					svg {
						rect {
							fill: #ffffff !important;
                            stroke-width: 0 !important;
						}

						path[stroke],
                        circle[stroke] {
							stroke: #111111 !important;
						}

						path[fill] {
							fill: #111111 !important;
						}
					}

					span {
						color: #ffffff;
						@include medium;
					}
				}
			}
		}
	}
}