

.text-page-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    background-color: $color-black;

    > div{ 
        @include large-screen {
            max-width: 100rem;
        } 

        @include small-screen {
           max-width: 88rem;
        }
    }

    p{
        @include large-screen {
            margin-bottom: 3rem;
            font-size: 2.8rem;
        } 

        @include small-screen {
            margin-top: 2rem;
            font-size: 2.2rem;
        }
    }

    .btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;

        @include large-screen {
            margin-top: 18.5rem;
        } 

        @include small-screen {
            margin-top: 16.5rem;
        }
 
        .btn{ 
            @include medium;
           
            @include small-screen {
                margin-left: 1.5rem;
                margin-right: 1.5rem; 
            }

            @include large-screen {
                margin-left: 2rem;
                margin-right: 2rem;
            } 
        }
    }
}