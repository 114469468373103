@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin img-fluid {
    max-width: 100%;
    height: auto;
    display: block;
}

@mixin appearance($value) {
    -moz-appearance: $value;
    -webkit-appearance: $value;
    appearance: $value;
}


@mixin center($position) {
    position: absolute;
  
    @if $position == 'vertical' {
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
    @else if $position == 'horizontal' {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translate(-50%);
    }
    @else if $position == 'both' {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
