.login-wrapper {
	.bg {
		.image {
			background-image: url("/assets/img/bg-login.jpg");
			background-size: cover;
		}

		.shadow {
			background-color: rgba(17, 17, 17, 0.8);
		}
	}

	.content {
		display: flex;
		justify-content: space-between;

		@include large-screen {
			padding: 20rem 13rem 0 13rem;
		}

		@include small-screen {
			padding: 13rem 8rem 0 8rem;
		}
	}

	.text-container {
		@include large-screen {
			width: 60%;
			padding-right: 15rem;
		}

		@include small-screen {
			width: 50%;
			padding-right: 7rem;
		}

		h5 {
			@include large-screen {
				margin-top: 1.8rem;
			}

			@include small-screen {
				margin-top: 1.3rem;
			}
		}

		.btn {
			@include large-screen {
				margin-top: 10rem;
			}

			@include small-screen {
				margin-top: 5rem;
			}
		}
	}

	.form {
		width: 40%;
	}

	#password {
		&:focus {
			border-color: transparent;
		}
		&.selected {
			border-color: #ffffff;
		}
	}

	.password-switcher {
		appearance: none;
		border: 0.4rem solid transparent;
		margin-top: -0.4rem;
		margin-right: -0.4rem;
		background: $color-lighter-black;
		border-radius: 50%;
		position: relative;
		margin-left: 1.5rem;
		&.open-eye {
			svg {
				&:first-child {
					opacity: 1;
				}
				&:nth-child(2) {
					opacity: 0;
				}
			}
		}

		@include large-screen {
			min-width: 9rem;
			height: 9rem;
		}

		@include small-screen {
			min-width: 6rem;
			height: 6rem;
		}

		&.selected,
		&:hover,
		&:focus {
			border: 0.4rem solid #ffffff;
			outline: none;
		}

		svg {
			position: absolute;
			transform: translate(-50%, -50%);
			height: auto;

			@include large-screen {
				width: 3.4rem;
			}

			@include small-screen {
				width: 3rem;
			}

			&:first-child {
				opacity: 0;
			}
		}
	}

	.snackbar {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		@include large-screen {
			bottom: 12rem;
		}

		@include small-screen {
			bottom: 8rem;
		}
	}
}
