#no-connection{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 100000;
    display: none;

    h3{
        margin-bottom: 4rem;
    }

    svg{
        display: block; 
        width: 6rem;
        height:6rem;
        margin: 3rem auto; 
        background: 0px 0px; 
        shape-rendering: auto;
    }

    .btn-wrapper{
        margin-top: 8rem;
    }

    &.visible{
        display: block;
    }

    &.hidden{
        display: none;
    }
}