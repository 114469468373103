#player-rtuk-container{
    position: absolute;
    top: 10rem;
    left: 0; 
    padding: 1rem 1.4rem 1rem 7rem;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 10;
    background-color: rgba(#01020A, .5);  
    white-space: nowrap;
    border-right: .4rem solid #D9D9D9; 
    user-select: none;
    pointer-events: none; 

    &.hide{
        display: none;
    }

    > div{
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 1.4rem;
        &:first-child{
            margin-left: 0;
        }
    }

    .text-span{
        @include semi-bold;
    }
    
    .rtuk{
       margin-right: 1rem;
    }
}