
// altyazı stilleri için ref: https://stackoverflow.com/a/48633014

video::-webkit-media-text-track-container {
    position: initial !important;
    overflow: visible !important;
    transform: translateY(-5rem) !important; // altyazılar aşağıda duruyordu, yukarı çıkarıldı.
}

.vestel video::-webkit-media-text-track-container {
    transform: translateY(-1rem) !important; // altyazılar aşağıda duruyordu, yukarı çıkarıldı.
}

// video::-webkit-media-text-track-background {
//     // Style the text background
//     background-color: black !important;
//     overflow: visible !important;
// }

// video::-webkit-media-text-track-display-backdrop {
//     background-color: black !important;
//     overflow: visible !important;
//   }

// video::-webkit-media-text-track-display {
//     // Style the text itself
// }

#player-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

// .shaka-bottom-controls {
//     display: none;
// }

// .shaka-play-button-container {
//     display: none !important;
// }

#video-main-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #111111;
    overflow: hidden;

    &.disable-controls{
        .hide-element{
            visibility: hidden !important;
        }
    }

    &.video-overlay-hidden {
        .shadow-layout {
            opacity: 0;
        }

        .gradient-layout {
            opacity: 0;
        }

        .hide-element {
            opacity: 0;

            &.top {
                transform: translateY(-30px);
            }

            &.bottom {
                transform: translateY(30px);
            }
        }
    }

    &.video-ended {

        .player-buttons {
            display: none;
        }

        .player-control {
            display: none;
        }

    }

    &.video-bottom-toggle-box-active{
       .back-button.in-player{
        display: inline-flex;
       } 
       &.subtitle{
        #subtitles-box{
            display: flex;
        }
       }
       &.track{
        #track-box{
            display: flex;
        }
       }
    }

    .hide-element {
        opacity: 1;

        &.top {
            transform: translateY(0);
        }

        &.bottom {
            transform: translateY(0);
        }
    }

    .video-player {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .gradient-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #111111 0%, rgba(0, 0, 0, 0) 28.65%, rgba(0, 0, 0, 0) 64.58%, #111111 100%);
        opacity: 0.8;
        z-index: 2;
        pointer-events: none;
    }

    .shadow-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color-black;
        opacity: 0.5;
        pointer-events: none;
        z-index: 3;
    }

    .next-episode-layout {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; 
        z-index: 3;   
        display: flex;
        align-items:flex-end; 
        justify-content: flex-end;

        &.active{      
            .btn-intro{   
                .into-bg-slide{
                    animation-play-state: running; 
                }
            }  
        }

        .btn-intro{ 
            border-color: transparent;

            .into-bg-slide{
                animation-play-state: paused; 
            }

            &:hover,
            &:focus,
            &.selected{
                border-color: #FFFFFF;
                background-color:$color-lighter-black;
                color: $color-light-title-grey;

                i{
                    svg{
                        path{
                            color: $color-light-title-grey;
                        }
                    }
                }
            }
        }

        @include large-screen {
            padding-right: 5.7rem;
            padding-bottom: 24rem;
        }

        @include small-screen {
            padding-right: 6rem;
            padding-bottom: 20rem;
        }

    }


    .btn-video-back {
        position: absolute;
        top: 4rem;
        left: 6rem;
        z-index: 4;
    }

    .player-buttons {
        position: absolute;
        left: 6rem;
        right: 6rem;
        z-index: 4;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include large-screen {
            bottom: 7rem;
        }

        @include small-screen {
            bottom: 4rem;
        }

        .left-button {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .btn-player {

                @include large-screen {
                    margin-right: 2rem;
                }

                @include small-screen {
                    margin-right: 3rem;
                }

            }

            .btn-circle { 
                    position: relative;
            
                    .border-line{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                    }
            
                    &.active{ 
                        color: #fff;
                        background: rgba(51, 51, 51, 0.7);
                        
                        .border-line{
                            opacity: 1;
                        }
            
                        &.selected,
                        &:hover,
                        &:focus{      
                            background: #fff;
                            color: $color-black;
                        }
            
                    }

                +.btn-circle {

                    @include large-screen {
                        margin-left: 3rem;
                    }

                    @include small-screen {
                        margin-left: 1rem;
                    }

                }

            }

        }

    }

    .player-control {
        position: absolute;
        left: 6rem;
        right: 6rem;
        z-index: 4;
        height: 4rem;
        display: flex;
        align-items: stretch;

        // .play-pause-button{

        // }
        @include large-screen {
            bottom: 16.5rem;
        }

        @include small-screen {
            bottom: 11rem;
        }

    }

    .player-range-container {
        margin-left: 3rem;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            @include medium;
            color: #fff;

            @include large-screen {
                font-size: 2.8rem;
                line-height: 3rem;
                min-width: 10rem;
            }

            @include small-screen {
                font-size: 2.2rem;
                line-height: 2.4rem;
                min-width: 8rem;
            }
        }

        #current-time {
            text-align: right;
        }

        #total-time {
            text-align: left;
        }

        .timeline-range {
            flex-grow: 1;
            align-self: stretch;
            margin: 0 1rem;
            display: flex;
            align-items: center;
            position: relative;

            .vertical-line {
                position: absolute;
                height: 3.2rem;
                border-radius: 0.6rem;
                width: 0.6rem;
                background-color: #ffffff;
                top: 0.4rem;
                margin-left: -0.6rem;
                z-index: 2;
            }

            .line {
                width: 100%;
                height: 1rem;
                background: $color-light-grey;
                border-radius: 0.6rem;
                flex-grow: 1;
                position: relative;
                z-index: 1;
                overflow: hidden;

                i {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background: $color-primary;
                    // border-radius: 0.6rem;
                    max-width: 100%;
                }
            }

        }
    }

    .intro-button-container {
        position: absolute;
        left: 6rem;
        right: 6rem;

        z-index: 4;

        @include large-screen {
            bottom: 23rem;
        }

        @include small-screen {
            bottom: 18rem;
        }

    }

    .final-button-container {
        position: absolute;
        left: 6rem;
        right: 6rem;
        bottom: 13.5rem;
        z-index: 4;
        display: none;
        justify-content: flex-end;
        align-items: center;

        .btn {
            &+.btn {
                margin-left: 1.3rem;
            }
        }

    }

    .recommended-videos-container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 4;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 5rem;
        padding-top: 3rem;

        @include medium;
        color: #FFFFFF;
        background-color: rgba(17, 17, 17, .6);

        @include large-screen {
            font-size: 2.8rem;
            line-height: 3rem;
            padding-left: 6rem;
            padding-right: 6rem;
        }

        @include small-screen {
            font-size: 2.2rem;
            line-height: 2.4rem;
            padding-left: 6rem;
            padding-right: 6rem;
        }

        >div {
            min-width: 118rem;

            @include large-screen {}

            @include small-screen {}

        }

        .title {
            text-align: center;
            margin-bottom: 3rem;
            display: block;
        }

        .title-wrapper {
            display: flex;

            .item {
                border: 0.4rem solid transparent;
                border-radius: 1rem;
                flex-grow: 1;
                background: transparent;
                padding: 0;

                +.item {
                    margin-left: 1rem;
                }

                &:hover,
                &:focus,
                &:active,
                &.selected {
                    border: 0.4rem solid #ED1C24;
                }
            }

            img {
                display: block;
                border-radius: 1rem;
                width: 100%;
                object-fit: cover;

                @include large-screen {
                    height: 19rem;
                }

                @include small-screen {
                    height: 12rem;
                }
            }
        }

        .count {
            display: block;

            @include large-screen {
                margin-top: 3rem;
            }

            @include small-screen {
                margin-top: 2rem;
            }

        }

    }



}



.player-preview-card {
    position: absolute;
    transform: translateX(-50%);
    left: 0;
    margin-left: 0.3rem;
    border: 0.4rem solid #ffffff;
    border-radius: 0.6rem;
    overflow: hidden;

    @include large-screen {
        height: 27.5rem;
        width: 49rem;
        bottom: calc(100% + 8rem);
    }

    @include small-screen {
        height: 16rem;
        width: 28rem;
        bottom: calc(100% + 2rem);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.3rem;
    }

    span {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        text-align: center;
        border-bottom-left-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
        background: $color-light-black;

        @include large-screen {
            padding: 1rem 0;
        }

        @include small-screen {
            padding: 0.4rem 0;
        }

    }

}




.live-badge {
    display: inline-flex;
    align-items: center;
    background: $color-primary;
    color: #ffffff;
    @include semi-bold;
    border-radius: 4.8rem;
    z-index: 4;
    position: absolute;
    top: 4rem;
    right: 6rem;
    padding: 1rem 1.7rem;

    @include large-screen {
        font-size: 3.2rem;
        line-height: 3.5rem;
        padding: 1rem 1.7rem;
    }

    @include small-screen {
        font-size: 2.6rem;
        line-height: 2.8rem;
        padding: 0.35rem 1.5rem;
    }

    i {
        display: inline-flex;
        width: 2rem;
        height: 2rem;
        background: #ffffff;
        border-radius: 50%;
        margin-right: 1.5rem;

        @include large-screen {
            width: 2rem;
            height: 2rem;
        }

        @include small-screen {
            width: 1.2rem;
            height: 1.2rem;
        }

    }
}



.demo-player-control {
    position: absolute;
    left: 6rem;
    top: 6rem;
    height: 80rem;
    width: 140rem;
    background-color: #000;
    z-index: 10;
}






#player-progress {
    cursor: pointer;
}




// // Video time line focus işlemi
// const playerProgress = document.getElementById('player-progress'); 

// if(playerProgress){
//     const progressWidth = playerProgress.clientWidth;
//     playerProgress.addEventListener("click", function(event){ 
//         var offsetX = event.offsetX; 
//         var x = parseInt((offsetX / progressWidth) * 100);

//         changeProgress(x);
//     });

// }

// function changeProgress(clickTime){
//  // console.log('clickTime : ',clickTime);
//   let total = controllerRef.current.videoElement.duration; 
//   controllerRef.current.videoElement.currentTime = ( total * clickTime ) / 100;
// }