.video-bottom-toggle-box {
	width: 100%;
	padding: 0 6rem;
	position: absolute;
	left: 0;
	bottom: 6rem;
	z-index: 20;
	display: none;
	align-items: flex-start;
	justify-content: flex-start;

	&.active {
		display: flex;
		z-index: 30;
	}

	> div {
		display: inline-flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
	
		@include small-screen {
			margin-right: 6rem;
		}

		@include large-screen {
			margin-right: 9rem;
		}
	}

	.title-elem {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
		@include medium;
		min-height: 3.2rem;
        margin-bottom: 3.2rem;

		@include small-screen {
			font-size: 2rem;
			line-height: 2.6rem;
		}

		@include large-screen {
			font-size: 2.8rem;
			line-height: 4.8rem;
		}

        svg{
            margin-right: 1.5rem;
        }
	}

	.buttons-huge {
		display: inline-flex;
		align-items: center;
		justify-content: flex-start;
	}

	.btn {
		line-height: 1;
		border-radius: 5rem;
		@include medium;

        margin-right: 3.2rem;

		&:focus {
			svg {
				path {
					stroke: $color-black;
				}
			}
		}

		@include small-screen {
			padding: 1.2rem 2rem;
			font-size: 2rem;
		}

		@include large-screen {
			padding: 1.55rem 2.5rem;
			font-size: 2.8rem;
		}
	}
}
