.loading-fullscreen {
    z-index: 10;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    background: rgba(17, 17, 17, .85);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
        @include large-screen {
            width: 10rem;
            height: 10rem;
        }

        @include small-screen {
            width: 7rem;
            height: 7rem;
        }
    }
}