.main-wrapper {
    &.search {
        .playlist-wrapper {
            &.skeleton {
                >.text-large {
                    display: inline-block;
                    height: 4.2rem;
                    width: 22rem;
                    border-radius: 1rem;
                }
            }

        }
    }
}