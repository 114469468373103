.form-group {
    width: 100%;
	display: flex;
    padding: 0 0 2rem 0;

    // &.space-around{
    //     justify-content: space-around;
    // }

    // &.space-between{
    //     justify-content: space-between;
    // }
}
