.empty-list-wrapper{
    text-align:center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 4;

    @include small-screen {
        padding-left: 18rem;
        height: calc(100% - 14.8rem);
    }

    @include large-screen {
        padding-left: 21rem; 
        height: calc(100% - 23.4rem);
    }

    &.full{
        @include small-screen { 
            height: 100%;
        }
    
        @include large-screen { 
            height: 100%;
        }
    }

    svg{
        @include small-screen {
            transform: scale(.7);
            margin-bottom: -5rem;
        }
    }

    h3{
        @include small-screen {
            margin-bottom: 1.5rem; 
        }
    
        @include large-screen {
            margin-bottom: 2.5rem;
            margin-top: 3rem;
        }
    }
}