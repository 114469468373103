.snackbar {
	width: 100%;
	max-width: 50rem;
	padding: 3.2rem 3.6rem;
	margin-bottom: 2.4rem;
	border-radius: 4rem;
	@extend .text-medium;

	&.snackbar-validation {
		background-color: #ffffff;
		color: $color-lighter-black;
	}

	&.snackbar-error {
		background-color: $color-primary;
		color: #ffffff;
	}

	&.snackbar-success {
		background: $color-success;
		color: #ffffff;
	}

	&.snackbar-free-account-warning {
		position: absolute;
		bottom: 0;
		max-width: 51rem;

		@include large-screen {
			bottom: 5rem;
		}

		@include small-screen {
		}
	}
}
