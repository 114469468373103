.rtuk {
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;

  @include large-screen {
    width: 2.8rem;
    height: 2.8rem;
  }

  @include small-screen {
    width: 2.4rem;
    height: 2.4rem;
  }

  & + .rtuk {
    @include large-screen {
      margin-left: 1rem;
    }

    @include small-screen {
      margin-left: 0.7rem;
    }
  }

  &.content7Plus {
    background-image: url('/assets/img/rtuk/content7Plus.svg');
  }
  &.content13Plus {
    background-image: url('/assets/img/rtuk/content13Plus.svg');
  }
  &.content18Plus {
    background-image: url('/assets/img/rtuk/content18Plus.svg');
  }
  &.contentAll {
    background-image: url('/assets/img/rtuk/contentAll.svg');
  }
  &.contentNegative {
    background-image: url('/assets/img/rtuk/contentNegative.svg');
  }
  &.contentSexuality {
    background-image: url('/assets/img/rtuk/contentSexuality.svg');
  }
  &.contentViolence {
    background-image: url('/assets/img/rtuk/contentViolence.svg');
  }
}