.episode-card {
    &.skeleton {
        .detail {
            span {
                width: 40rem;
                height: 3.5rem;
                border-radius: 1rem;
            }
            p{
                width: 100%;
                height: 9rem;
                border-radius: 1rem;
            }
            i{
                height: 2.6rem;
                width: 5rem;
                border-radius: 1rem;
            }
        }

    }
} 