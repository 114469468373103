
#featured-media-summary{
    &.skeleton{ 
        z-index: 999;
        pointer-events: none;

        .line{
            height: 6rem;
            width: 35rem;
            border-radius: 1rem; 
            max-width: 90%; 

            &.sm{
                height: 4rem;
                width: 20rem;
            }

            &.lg{ 
                width: 78rem; 
                
                @include large-screen { 
                    margin-top: 2.1rem;
                    height: 12rem;
                } 
        
                @include small-screen {
                    margin-top: 1.5rem;
                    height: 8rem;
                }  
            }
        }

        .logo{
            border-radius: 1rem;
        }

        .btn{
            color: transparent;
            background-color: transparent;
        }

        .add-to-list-btn{ 
            width: 29rem; 
        }
        
        .info{
            width: 26rem;
            height: 3.3rem;
            border-radius: 1rem;
        }

        .episode-buttons{

            .episode-title{
                width: 30rem;
                height: 3.5rem;
                border-radius: 1rem;
            }

            .episode-buttons-list{
                .btn{

                }
            }

        }
        
    }
}
