.btn {
    background-color: $color-lighter-black;
    border: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    @include regular;
    color: $color-light-title-grey;

    &:disabled {
        cursor: not-allowed;
    }

    &.selected,
    &:hover:not(.btn-intro),
    &:focus:not(.btn-intro) {
        background-color: #ffffff;
        color: $color-black;

        i {
            svg {
                path {
                    color: $color-black;
                }
            }
        }
    }

    i {
        display: inline-flex;

        @include large-screen {
            margin-right: 2rem;
        }

        @include small-screen {
            margin-right: 1.5rem;
        }

        svg {
            width: auto;
        }
    }
}

button {
    &:focus {
        outline: none;
    }
}

.btn-full-width {
    width: 100%;
}

.btn-large,
.btn-medium,
.btn-small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.btn-large {
    @include large-screen {
        border-radius: 6rem;
        height: 10rem;
        line-height: 10rem;
        padding: 0 8rem;
        font-size: 3.4rem;
    }

    @include small-screen {
        border-radius: 4rem;
        height: 7rem;
        line-height: 7rem;
        padding: 0 7rem;
        font-size: 2.8rem;
    }
}


.btn-medium {
    @include large-screen {
        border-radius: 5.8rem;
        height: 6.5rem;
        line-height: 6.5rem;
        padding: 0 5.5rem;
        font-size: 2.8rem;
    }

    @include small-screen {
        border-radius: 4rem;
        height: 5rem;
        line-height: 5rem;
        padding: 0 4rem;
        font-size: 2.2rem;
    }
}


.btn-small {
    @include large-screen {
        border-radius: 5.8rem;
        height: 6rem;
        line-height: 6rem;
        padding: 0 5.5rem;
        font-size: 2.8rem;
    }

    @include small-screen {
        border-radius: 4rem;
        height: 4.5rem;
        line-height: 4.5rem;
        padding: 0 3rem;
        font-size: 2.2rem;
    }
}



/* Sadece giriş yap butonu için kullanılıyor, .btn-large ile birlikte kullanıldı */
.btn-red {
    background-color: $color-new-more-dark-grey;
    color: $color-light-title-grey;

    &:hover,
    &:focus {
        background-color: $color-primary;
        color: #FFFFFF;
        outline: none;
    }
}


.show-watch-now-button {
    i {
        svg {
            @include large-screen {
                height: 2.2rem;
            }

            @include small-screen {
                height: 2rem;
            }
        }
    }

    .video-continues {
        height: 0.6rem;
        background-color: #CCCCCC;
        margin-left: auto;
        margin-right: 0;
        overflow: hidden;
        position: relative;

        @include large-screen {
            width: 10rem;
        }

        @include small-screen {
            width: 8rem;
        }

        &:before {
            content: '';
            position: absolute;
            width: var(--percent);
            bottom: 0;
            top: 0;
            left: 0;
            background-color: $color-primary;
        }
    }
}

.btn-home-hero-cont-watch .video-continues{
    margin-left: 2rem;
}


.add-to-list-btn {
    i {
        svg {
            @include large-screen {
                height: 2.2rem;
            }

            @include small-screen {
                height: 2rem;
            }
        }
    }

    &.added{
        i{
            transform: rotate(45deg);
        }
    }
}


// .fake-sidebar{
//   width: 10px;
//     height: 10px;
//     display: inline-flex;
//     align-items: center;
//     position: absolute;
//     top: 25rem;
//     /* -webkit-user-select: none; */
//     user-select: none;
//     cursor: pointer;
//     left: 0.3999999999999986rem;
//     font-size: 2.2rem;
//     line-height: 2.4rem;
// }

.back-button {
    z-index: 5;
    display: inline-flex;
    align-items: center;
    position: absolute;
    top: 4.0rem;
    // pointer-events: none;
    user-select: none;
    cursor: pointer;
    color: $color-lighter-grey;
    background-color: transparent;
    border: none;

    &.in-player{
        display: none;  

        @include large-screen {
            top: 5rem;
            left: 5rem; 
        }
    
        @include small-screen {
            top: 4rem;
            left: 5rem; 
        }
    }

    &:hover,
    &:focus,
    &.selected {
        color: #ffffff;
    }

    i {
        display: inline-flex;
        margin: 0 1.3rem;
    }

    @include large-screen {
        left: 21rem;
        font-size: 2.4rem;
        line-height: 2.6rem;
    }

    @include small-screen {
        left: 18rem;
        font-size: 2.2rem;
        line-height: 2.4rem;
    }

    &.show{
        display: inline-flex !important;
    }

}




.btn-circle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgba(51, 51, 51, 0.7);
    // backdrop-filter: blur(15px);
    color: #fff;
    border: none;

    @include large-screen {
        width: 6rem;
        height: 6rem;
        border-radius: 6rem;
    }

    @include small-screen {
        width: 5rem;
        height: 5rem;
        border-radius: 5rem;
    }

    &:hover,
    &.active,
    &.selected {
        cursor: pointer;
        background-color: #fff;
        color: $color-black;
    }

    &:hover,
    &.selected {
        path{
            stroke: $color-black;
        }
    }
}

// .webos .btn-circle,
// .vestel .btn-circle{
//     -webkit-backdrop-filter: none !important;
//     backdrop-filter: none !important;
// }




.btn-player {
    display: inline-flex;
    align-items: center;
    @include semi-bold;

    @include large-screen {
        border-radius: 3rem;
        height: 6rem;
        line-height: 6rem;
        padding: 0 6rem;
        font-size: 2.8rem;
    }

    @include small-screen {
        border-radius: 2.5rem;
        height: 5rem;
        line-height: 5rem;
        padding: 0 4.3rem;
        font-size: 2.2rem;
    }

}

.btn-next-episode {
    padding: 0 3.3rem;

    i {
        @include large-screen {}

        @include small-screen {
            margin-right: 2rem;
        }
    }

}

.btn-intro {
    border: .2rem solid #FFFFFF;
    position: relative;
    overflow: hidden;

    &:hover,
    &:focus,
    &.selected {
        background-color: transparent;
        color: #FFFFFF;
    }

    span {
        position: relative;
        z-index: 2;
        mix-blend-mode: difference;
    }

    .into-bg-slide {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        background-color: #FFFFFF;
        z-index: 1;
        width: 0;
        animation: slideRight var(--intro-time) linear forwards; 
        mix-blend-mode: difference;
    }

}

@keyframes slideRight {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}


.btn-subtitle { 
    padding: 0 2.5rem;

    &.hidden{
        display: none;
    }
}

.btn-has-check{
    display: inline-flex;
    align-items: center;
    justify-content: flex-start; 
    min-height: 4.8rem;

    svg{
        margin-left: 1.5rem;
    }

    &:hover,
    &.selected{
        svg{
            path{
                stroke: $color-black;
            }
        }
    }
}


.btn-video-back {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: transparent;
    border: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &.selected {
        svg {
            path {
                fill: #fff;
                stroke: $color-black;
            }
        }
    }

    @include regular;

    @include large-screen {
        font-size: 2.8rem;
        line-height: 4.8rem;
    }

    @include small-screen {
        font-size: 2.2rem;
        line-height: 3rem;
    }

    svg {

        @include large-screen {
            width: 4.8rem;
            height: 4.8rem;
            margin-right: 2rem;
        }

        @include small-screen {
            width: 3rem;
            height: 3rem;
            margin-right: 1rem;
        }
    }

    span {
        margin-left: 2.4rem;
    }
}



.btn-try-again {

    @include large-screen {
        padding: 0 15.5rem;
    }

    @include small-screen {
        padding: 0 14rem;
    }
}

.btn-more-detail {
    @include large-screen {
        padding: 0 12.3rem;
    }

    @include small-screen {
        padding: 0 12.5rem;
    }
}


.btn-more-detail {
    @include large-screen {
        padding: 0 12.3rem;
    }

    @include small-screen {
        padding: 0 12.5rem;
    }
}


.btn-cancel {
    @include large-screen {
        padding: 0 7.2rem;
    }

    @include small-screen {
        padding: 0 4.6rem;
    }
}

.btn-sign-out {
    display: inline-flex;

    @include large-screen {
        padding: 0 6.2rem;
        line-height: 6rem;
        height: 6rem;
    }

    @include small-screen {
        padding: 0 3.8rem;
        line-height: 5rem;
        height: 5rem;
    }
}



.btn-season {
    width: 100%; 
    padding: 0 3rem;
    display: block;
    span{

        &.left-span{
            float: left;
        }

        &.right-span{
            float: right;
        }
    }

    &.active {
        background-color: $color-semi-light-grey;
        color: $color-black;

        span {
            color: $color-light-black;
        }
    }

    &.selected {
        background-color: #ffffff;
        color: $color-black;

        span {
            color: $color-light-black;
        }
    }

    span {
        font-size: 2.2rem;
    }
}



.btn-play-pause {
    display: inline-block;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:hover,
    &:focus,
    &.active,
    &.selected {
        outline: none;

        svg {
            path {
                fill: $color-primary;
                stroke: $color-primary;
            }
        }
    }

    svg {
        path {
            &.pausePath {
                opacity: 0;
            }

            //&.playPath {}
        }
    }
}


.btn-logout{

    @include small-screen {
        min-width: 37rem;
    }

    @include large-screen {
         min-width: 42rem;
         height: 6rem;
         line-height: 6rem;
    }

}

.btn-cancel-logout{

    @include small-screen {
        min-width: 37rem;
    }
    
    @include large-screen {
         min-width: 40rem;
         height: 6rem;
         line-height: 6rem;
    }

}

.btn-no-connection{

    @include small-screen {
        min-width: 37rem;
    }
    
    @include large-screen {
         min-width: 40rem;
         height: 6rem;
         line-height: 6rem;
    }

}