.premium-card{
   
    border-radius: 1rem;
    background-color: $color-light-black;
    color: $color-light-grey;
    @include medium; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include large-screen {  
        padding: 0 6rem;
        font-size: 3rem;
        line-height: 4rem;
        margin-bottom: 4rem; 
        min-height: 21.5rem;
    } 

    @include small-screen {  
        padding: 0 5rem;
        font-size: 2.4rem;
        line-height: 3.4rem;
        margin-bottom: 3rem; 
        min-height: 13.5rem;
    }

    b{
        color: #ffffff;
        font-weight: 400;
    }

    h4{
        margin-bottom: 1.5rem;
        @include semi-bold;
        color: #ffffff;
    }
}   